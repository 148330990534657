export const logo = require('@/assets/logo-full.png');
export const drawerLogos = {
    normal: require('@/assets/photo_2023-08-31_17-34-48.png'),
    mini: require('@/assets/photo_2023-08-31_17-32-55.png')
};

export const colors = {
    primary:  '#253c99',    
    // #0b5b6f //
    // '#', // #253c99  #014a97  #  041943 
    primaryLight: '#ccddfd',
    //  '#', // #b2c1ff #c0dfff # a0ecff
    secondary: '#5371ad', // #f57f20 // #f57f20 #5371ad
    lightBlue:  '#103aa2', // #0996b9 # # 0996b9 85b901
}